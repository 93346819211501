import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import useUserSelectBox from 'components/aida/custom-form-hooks/useUserSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import CallPythonApi from 'components/common/custom-components/CallPythonApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SeoWizardContext } from './Context';
import useReportLanguageSelectBox from 'components/aida/custom-form-hooks/useReportLanguageSelectBox';
import usePlansSelectBox from 'components/aida/custom-form-hooks/usePlansSelectBox';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SaveAsDraftWithNoteModal from './SaveAsDraftWithNoteModal';
import { steps } from './Sidebar';
import { AidaAppContext } from 'context/AidaAppContext';
import ExampleCompanyDescriptionModal from './ExampleCompanyDescriptionModal';
import { toast } from 'react-toastify';

const MainInformationForm = () => {
  const aidaAppContext = useContext(AidaAppContext);

  const emptyFormData = {
    name: null,
    brand_name: null,
    domain: null,
    use_domain_anyway: false,
    company_description: null,
    email_for_reports: null,
    client_main_email: null,
    plan_id: null,
    number_of_keywords: null,
    status: null,
    country_id: null,
    language_id: null,
    report_language_id: null,
    managing_user_id: null
  };

  const [errors, setErrors] = useState(emptyFormData);
  const { handleSubmit, setValue, register, getValues, control, reset, watch } =
    useForm({
      defaultValues: {
        ...emptyFormData,
        managing_user_id: aidaAppContext.current_user?.id
      }
    });

  const ManagingUserSelectBox = useUserSelectBox('/users');
  const { CountrySelectbox } = useCountrySelectBox(
    'id',
    '/countries?for=seo_projects'
  );
  const { LanguageSelectBox } = useLanguageSelectBox();
  const { PlanSelectBox } = usePlansSelectBox();
  const { ReportLanguageSelectBox } = useReportLanguageSelectBox();

  const seoProjectContext = useContext(SeoWizardContext);
  const {
    nextStep,
    currentStep,
    refetchSeoProject,
    currentSeoProject,
    isLoadingSeoProjectDraft
  } = seoProjectContext;

  useEffect(() => {
    if (currentSeoProject) {
      reset(currentSeoProject?.main_information);
    }
    return () => {};
  }, [currentSeoProject, currentStep]);

  const CreateSeoProjectDraftMutation = useMutation(
    'create-seo-project-draft',
    formData => CallApi.post(`/seo-project-drafts`, formData),
    {
      onSuccess: json => {
        if (json?.status == 422) {
          setErrors(json?.errors);
        }
        if (json?.status == 200) {
          nextStep({
            ...seoProjectContext,
            currentSeoProject: json?.data
          });
        }
      }
    }
  );

  const UpdateSeoProjectDraftMutation = useMutation(
    'create-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData),
    {
      onSuccess: json => {
        if (json?.status == 422) {
          setErrors(json?.errors);
        }
        if (json?.status == 200) {
          refetchSeoProject();
          nextStep();
        }
      }
    }
  );

  const disableFields =
    isLoadingSeoProjectDraft ||
    CreateSeoProjectDraftMutation?.isLoading ||
    UpdateSeoProjectDraftMutation.isLoading;

  const onSubmit = (data, form) => {
    const formData = new FormData();

    formData.append('main_information', JSON.stringify(data));

    if (currentSeoProject?.id) {
      UpdateSeoProjectDraftMutation.mutate(formData);
    } else {
      CreateSeoProjectDraftMutation.mutate(formData);
    }
  };

  // Functionality related to generating company descriptions
  const [selectedReportLanguageName, setSelectedReportLanguageName] = useState('');
  const [selectedTextLanguageName, setSelectedTextLanguageName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] = useState(true);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const domainValue = watch('domain');
  const brandNameValue = watch('brand_name');
  useEffect(() => {
    setIsGenerateButtonDisabled(!(domainValue && (selectedTextLanguageName || selectedReportLanguageName)));
    previouslyGeneratedDescriptions.current = [];
  }, [domainValue, selectedTextLanguageName, selectedReportLanguageName, selectedCountry]);

  const previouslyGeneratedDescriptions = useRef([]);
  const generateCompanyDescription = async () => {
    // Call the aida-py generate-company-description endpoint to generate a new description using ChatGPT
    setShowLoadingSpinner(true);
    var result = false;
    try {
        result = await CallPythonApi.post("/generate-company-description/", {
            "url": domainValue, 
            "company_name": brandNameValue, 
            "language": selectedTextLanguageName ?? selectedReportLanguageName ?? "English",
            "previous_responses": previouslyGeneratedDescriptions.current
          }, true, true);
    } catch {
        toast.error(`Cannot generate company descriptions right now.`, {
            autoClose: 5000,
            position: 'bottom-right'
        });
        setShowLoadingSpinner(false);
        return;
    }
    if (result) {
        setValue('company_description', result["description"]);
        previouslyGeneratedDescriptions.current.push(result["description"]);
    }
    else {
        toast.error(`Failed to generate a company description - is the URL valid?`, {
            autoClose: 5000,
            position: 'bottom-right'
        });
    }
    setShowLoadingSpinner(false);
  }

  return (
    <>
      <Form id="seo-wizard-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>
            Project Name
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_name'}
            />
          </Form.Label>
          <Form.Control
            {...register('name', {
              required: true,
              disabled: disableFields
            })}
            isInvalid={errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            Company Name to display in text
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_brand_name'}
            />
          </Form.Label>
          <Form.Control
            {...register('brand_name', {
              required: true,
              disabled: disableFields
            })}
            isInvalid={errors.brand_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.brand_name && errors.brand_name.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            Website
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_domain'}
            />
          </Form.Label>
          <Form.Control
            {...register('domain', {
              required: true,
              disabled: disableFields
            })}
            isInvalid={errors.domain}
          />
          <Form.Control.Feedback type="invalid">
            {errors.domain && errors.domain.map(error => error)}
          </Form.Control.Feedback>
          {errors?.domain ? (
            <Form.Check
              {...register('use_domain_anyway')}
              label={
                <>
                  I want to use this URL anyway
                  <LocalStorageTooltip
                    className="ms-1"
                    identifier={'form_seo_project_field_use_domain_anyway'}
                  />
                </>
              }
            />
          ) : null}
        </Form.Group>
        
        <Row>
            <Col lg={4}>
                <Form.Group className="mb-3">
                <Form.Label>
                    Country
                    <LocalStorageTooltip
                    className="ms-1"
                    identifier={'form_seo_project_region'}
                    />
                </Form.Label>
                <Controller
                    control={control}
                    name="country_id"
                    render={({ field }) => (
                    <CountrySelectbox
                        className={errors?.country_id && 'is-invalid'}
                        isSearchable
                        isDisabled={disableFields}
                        required
                        onChange={country => {
                            field.onChange(country.id);
                            setValue('language_id', country.default_language_id);
                            setValue(
                                'report_language_id',
                                country.default_report_language_id
                            );
                            setSelectedCountry(country.name); // Save the plaintext name
                        }}
                        value={field.value}
                    />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.country_id && errors.country_id.map(error => error)}
                </Form.Control.Feedback>
                </Form.Group>
            </Col>
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Text Language
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_language'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="language_id"
                render={({ field }) => (
                  <LanguageSelectBox
                    className={errors.language_id && 'is-invalid'}
                    isSearchable
                    isDisabled={disableFields}
                    required
                    name="language_id"
                    onChange={language => {
                      field.onChange(language.id);
                    }}
                    onLanguageChange={language=> {
                        setSelectedTextLanguageName(language.name); // Save the plaintext name
                    }}
                    value={field.value}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.language_id && errors.language_id.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Report Language
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_field_report_language'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="report_language_id"
                render={({ field }) => (
                  <ReportLanguageSelectBox
                    className={errors.report_language_id && 'is-invalid'}
                    isSearchable
                    isDisabled={disableFields}
                    onChange={report_language => {
                      field.onChange(report_language.id);
                      setSelectedReportLanguageName(report_language.name); // Save the plaintext name
                    }}
                    value={field.value}
                    required
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.report_language_id &&
                  errors.report_language_id.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label className='w-100 d-flex flex-row align-items-end'>
            Company Description
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_company_description'}
            />
            <ExampleCompanyDescriptionModal /> 
            <div style={{marginLeft: "auto", display: "block", marginRight: "5px"}}>
              <LocalStorageTooltip
                className="ms-1"
                identifier={'form_seo_project_generate_company_description'}
              />   
            </div>        
            <Button disabled={isGenerateButtonDisabled} onClick={generateCompanyDescription} 
              title="Generate a description using AI."
                className="rounded-pill ms-2"
                variant="outline-primary"
                style={{display: "block", opacity: isGenerateButtonDisabled ? 0.4 : 1, width: "190px"}}>
                  {showLoadingSpinner ? (
                    <>
                      <Spinner
                        style={{width: "16px", height: "16px"}}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Generating...
                    </>
                  ) : (
                    'Generate description'
                  )}
            </Button>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            {...register('company_description', { disabled: disableFields })}
            isInvalid={errors.company_description}
          />
          <Form.Control.Feedback type="invalid">
            {errors.company_description &&
              errors.company_description.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
            <Col lg={6}>
                <Form.Group className="mb-3">
                <Form.Label>
                    Client Email
                    <LocalStorageTooltip
                    className="ms-1"
                    identifier={'form_seo_project_field_client_main_email'}
                    />
                </Form.Label>
                <Form.Control
                    type="email"
                    {...register('client_main_email', {
                    required: true,
                    disabled: disableFields
                    })}
                    isInvalid={errors.client_main_email}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.client_main_email?.map(error => error)}
                </Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col lg={6}>
                <Form.Group className="mb-3">
                <Form.Label>
                    CC emails for reports
                    <LocalStorageTooltip
                    className="ms-1"
                    identifier={'form_seo_project_field_client_cc_email'}
                    />
                </Form.Label>
                <Form.Control
                    type="email"
                    multiple
                    {...register('email_for_reports', {
                    disabled: disableFields
                    })}
                    isInvalid={errors.email_for_reports}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email_for_reports &&
                    errors.email_for_reports.map(error => error)}
                </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Number of keywords
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_field_number_of_keywords'}
                />
              </Form.Label>
              <Form.Select
                {...register('number_of_keywords', {
                  required: true,
                  disabled: disableFields
                })}
                isInvalid={errors.number_of_keywords}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={"custom"}>Custom</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.number_of_keywords &&
                  errors.number_of_keywords.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Subscription
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_field_subscription'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="plan_id"
                render={({ field }) => (
                  <PlanSelectBox
                    className={errors?.plan_id && 'is-invalid'}
                    isSearchable
                    isDisabled={disableFields}
                    required
                    onChange={plan => field.onChange(plan.id)}
                    value={field.value}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.plan_id && errors.plan_id.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Contact Person
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_seo_project_secondary_user'}
                />
              </Form.Label>
              <Controller
                control={control}
                name="managing_user_id"
                render={({ field }) => (
                  <ManagingUserSelectBox
                    isSearchable
                    isDisabled={disableFields}
                    className={errors.managing_user_id && 'is-invalid'}
                    name="managing_user_id"
                    onChange={user => field.onChange(user.id)}
                    required
                    value={field.value}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.managing_user_id &&
                  errors.managing_user_id.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Flex justifyContent="center" className="mt-4">
        <SaveAsDraftWithNoteModal dataToBeDrafted={getValues} />
        <IconButton
          disabled={currentStep?.step == steps[steps.length - 1].step}
          className="rounded-pill ms-2"
          variant="outline-primary"
          icon={
            UpdateSeoProjectDraftMutation.isLoading ? 'spinner' : 'arrow-right'
          }
          iconAlign="right"
          type="submit"
          spin={UpdateSeoProjectDraftMutation.isLoading}
          form="seo-wizard-form"
        >
          {UpdateSeoProjectDraftMutation.isLoading
            ? 'Updating draft...'
            : 'Next: Select Competitors'}
        </IconButton>
      </Flex>
    </>
  );
};

export default MainInformationForm;

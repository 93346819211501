export const AppSettings = {
  apiHost: process.env?.REACT_APP_API_HOST
    ? process.env?.REACT_APP_API_HOST
    : 'http://aida-api.herokuapp.com',
  apiUrl: process.env?.REACT_APP_API_URL
    ? process.env?.REACT_APP_API_URL
    : 'http://aida-api.herokuapp.com/api',
  pythonApiUrl: process.env?.REACT_APP_PY_API_URL,
  pythonApiToken: process.env?.REACT_APP_PY_API_TOKEN,
  log_dna_source_token: process.env?.REACT_APP_LOGTAIL_SOURCE_TOKEN,
  freshchat_token: process.env?.REACT_APP_FRESHCHAT_TOKEN,
  freshchat_host: process.env?.REACT_APP_FRESHCHAT_HOST,
};
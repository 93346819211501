import ManualAddKeywordModal from 'components/aida/accounts/wizard-sub-forms/ManualAddKeywordModal';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import { ExtractHostname } from 'helpers/utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { SeoWizardContext } from './Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { calculateFrequency } from 'helpers/aida-functions';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import logtail from 'services/logtail';
import SaveAsDraftWithNoteModal from './SaveAsDraftWithNoteModal';
import ChangeNumberOfKeywordsModal from './ChangeNumberOfKeywordsModal';
import classNames from 'classnames';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const SelectKeywords = () => {
  const seoProjectContext = useContext(SeoWizardContext);
  const { currentSeoProject, nextStep, prevStep } = seoProjectContext;

  const [initiallySelectedRowIds, setInitiallySelectedRowIds] = useState({});
  const [competitorDomains, setCompetitorDomains] = useState(
    currentSeoProject?.competitors
  );

  const columns = [
    {
      id: 'keyword',
      accessor: v => v[0].keyword,
      Header: (
        <>
          Keyword
          <LocalStorageTooltip
            identifier={'table-select-keywords-column-keyword'}
          />
        </>
      )
    },
    // {
    //   id: 'frequency',
    //   accessor: v => calculateFrequency(v),
    // },
    {
      id: 'score',
      accessor: v => {
        let myPosition = v.find(
          item =>
            ExtractHostname(item.url) ==
            currentSeoProject?.main_information?.domain
        )?.position;

        if (myPosition == null) myPosition = 100;

        const frequency = calculateFrequency(v);

        let score = frequency;

        if (myPosition > 20) score++;
        if (myPosition >= 40 && myPosition <= 80) score++;

        // #from Improvement 1, adding volume as 1 for all, as hiding rows using filter
        score++;

        score = score / (3 + competitorDomains.length);

        if (score > 1) {
          score = 1;
        }

        return Math.round(score * 100) + '%';
      },
      Header: (
        <>
          Score
          <LocalStorageTooltip
            identifier={'table-select-keywords-column-score'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      id: 'my_position',
      Header: (
        <>
          My Position
          <LocalStorageTooltip
            identifier={'table-select-keywords-column-my-position'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      accessor: v =>
        v.find(
          item =>
            ExtractHostname(item.url) ==
            ExtractHostname(currentSeoProject?.main_information?.domain)
        )?.position,
      sortType: (rowA, rowB, columnId, desc) => {
        let a = rowA.values[columnId];
        let b = rowB.values[columnId];

        /**
         * These first 3 conditions keep our null values at the bottom.
         */
        if (a === null && b !== null) {
          return desc ? -1 : 1;
        }

        if (a !== null && b === null) {
          return desc ? 1 : -1;
        }

        if (a === null && b === null) {
          return 0;
        }

        // at this point, we have non-null values and we should do whatever is required to sort those values correctly
        return a - b;
      },
      Cell: ({ cell }) => {
        if (cell.row.original[0]?.manually_added) {
          const GetMyPositionQuery = useQuery(
            [
              'my-position',
              `seo project ${currentSeoProject?.id}`,
              cell.row.original[0].keyword
            ],
            () =>
              CallApi.get(
                `/seo-project-drafts/${currentSeoProject?.id}/my-position?keyword=${cell.row.original[0].keyword}`,
                false
              ),
            {
              enabled: !KeywordsMutation.isLoading
            }
          );

          return (
            <>
              {GetMyPositionQuery.isLoading ? (
                <FontAwesomeIcon icon={'spinner'} spin />
              ) : (
                GetMyPositionQuery.data?.data?.rank_group
              )}
            </>
          );
        }

        return cell.value || null;
      }
    },
    {
      id: 'volume',
      accessor: v => v[0].volume,
      Header: (
        <>
          Volume
          <LocalStorageTooltip
            identifier={'table-select-keywords-column-volume'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      Cell: ({ cell }) => {
        if (cell.row.original[0]?.manually_added) {
          const GetSearchQuery = useQuery(
            [
              'google-search-volume',
              `seo project ${currentSeoProject?.id}`,
              cell.row.original[0].keyword
            ],
            () =>
              CallApi.get(
                `/seo-project-drafts/${currentSeoProject?.id}/google-ads-search-volume?keyword=${cell.row.original[0].keyword}`,
                false
              ),
            {
              enabled: !KeywordsMutation.isLoading
            }
          );

          return (
            <>
              {GetSearchQuery.isLoading ? (
                <FontAwesomeIcon icon={'spinner'} spin />
              ) : (
                GetSearchQuery.data?.data?.search_volume
              )}
            </>
          );
        }

        return cell.value || null;
      }
    },
    {
      id: 'competition',
      accessor: v => v[0].competition,
      Header: (
        <>
          Competition
          <LocalStorageTooltip
            identifier={'table-select-keywords-column-competition'}
          />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      Cell: ({ cell }) => {
        if (cell.row.original[0]?.manually_added) {
          const GetSearchQuery = useQuery(
            [
              'google-search-volume',
              `seo project ${currentSeoProject?.id}`,
              cell.row.original[0].keyword
            ],
            () =>
              CallApi.get(
                `/seo-project-drafts/${currentSeoProject?.id}/google-ads-search-volume?keyword=${cell.row.original[0].keyword}`,
                false
              ),
            {
              enabled: !KeywordsMutation.isLoading
            }
          );

          return (
            <span title={GetSearchQuery.data?.data?.competition_index}>
              {GetSearchQuery.isLoading ? (
                <FontAwesomeIcon icon={'spinner'} spin />
              ) : (
                `${
                  GetSearchQuery.data?.data?.competition_index
                    ? GetSearchQuery.data?.data?.competition_index
                    : 0
                } %`
              )}
            </span>
          );
        }

        return (
          <span className="cursor-pointer" title={cell.value}>
            {cell.value ? Math.round(cell.value * 100) : 0} %
          </span>
        );
      }
    }
  ];

  const [keywords, setKeywords] = useState([]);

  const KeywordsMutation = useMutation(
    ['keywords'],
    variables =>
      CallApi.post(
        `/research-keywords`,
        variables.formData,
        true,
        true,
        {},
        variables.signal
      ),
    {
      onSuccess: json => {
        const savedKeywords = currentSeoProject?.keywords || [];

        let keywordsArray =
          json?.data
            ?.filter(items =>
              items?.find(
                item =>
                  ExtractHostname(item.url) ==
                    currentSeoProject?.main_information?.domain ||
                  competitorDomains.includes(ExtractHostname(item.url))
              )
            )
            ?.filter(
              items =>
                // Improvement 1 Trello Ticket 147
                calculateFrequency(items) > 1
            ) || [];

        let keywordsArrayWithVolumeGreaterThan40 =
          keywordsArray?.filter(
            items =>
              // Improvement 1 Trello Ticket 147
              items[0].volume > 40
          ) || [];

        // Check #1 Trello Ticket 180
        if (
          keywordsArrayWithVolumeGreaterThan40.length >=
          6 * currentSeoProject.number_of_keywords
        ) {
          keywordsArray = keywordsArrayWithVolumeGreaterThan40;
        } else {
          let keywordsArrayWithVolumeGreaterThan15 =
            keywordsArray?.filter(items => items[0].volume > 15) || [];

          // Check #2 Trello Ticket 180
          if (
            keywordsArrayWithVolumeGreaterThan15.length >=
            6 * currentSeoProject.number_of_keywords
          ) {
            keywordsArray = keywordsArrayWithVolumeGreaterThan15;
          } else {
            // Check #3 Trello Ticket 180
            let keywordsArrayWithVolumeGreaterThan5 =
              keywordsArray?.filter(items => items[0].volume > 5) || [];

            keywordsArray = keywordsArrayWithVolumeGreaterThan5;
          }
        }

        logtail.info('Base api keywords', keywordsArray);

        const allKeywords = [
          ...savedKeywords
            .filter(
              keyword =>
                !keywordsArray?.map(item => item[0].keyword)?.includes(keyword)
            )
            .map(keyword => [
              {
                keyword: keyword,
                common_keywords: '-',
                total_keywords: '-',
                relevance: '-',
                points: '-'
              }
            ]),
          ...keywordsArray
        ];

        let initialSelectedRowIndexes = {};

        const indexesOfPreviouslySelectedRows = allKeywords
          ?.map((items, i) =>
            [...savedKeywords].includes(items[0]?.keyword) ? i : null
          )
          ?.filter(index => index != null);

        indexesOfPreviouslySelectedRows.forEach(index => {
          initialSelectedRowIndexes[index] = true;
        });

        setInitiallySelectedRowIds(initialSelectedRowIndexes);

        setKeywords(allKeywords);

        logtail.warn('Final Keywords', allKeywords);
      }
    }
  );

  useEffect(() => {
    const researchKeywordsController = new AbortController();

    if (competitorDomains) {
      var formData = new FormData();

      formData.append('my_domain', currentSeoProject?.main_information?.domain);
      formData.append(
        'country_id',
        currentSeoProject?.main_information?.country_id
      );
      formData.append('competitor_domains', competitorDomains.join('\n'));

      KeywordsMutation.mutate({
        formData: formData,
        signal: researchKeywordsController.signal
      });
    }

    return () => {
      researchKeywordsController.abort();
    };
  }, [competitorDomains]);

  const UpdateSeoProjectDraftMutation = useMutation(
    'update-seo-project-draft',
    formData =>
      CallApi.put(`/seo-project-drafts/${currentSeoProject?.id}`, formData),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          nextStep({
            currentSeoProject: json?.data
          });
        }
      }
    }
  );

  const saveKeywords = selectedRows => {
    const formData = new FormData();

    let selectedKeywords = selectedRows.map(row => row.original[0]?.keyword);

    formData.append('keywords', JSON.stringify(selectedKeywords));

    UpdateSeoProjectDraftMutation.mutate(formData);
  };

  const NextPrevButtons = ({ selectedRowIds, data }) => {
    // Fix for issue with V7 of TanStack Table, see https://github.com/TanStack/table/issues/2210
    let selectedFlatRows = Object.keys(selectedRowIds).map(rowId => ({
      original: data[rowId]
    }));

    const isNextStepButtonDisabled =
      selectedFlatRows?.length < 1 ||
      selectedFlatRows?.length >
        currentSeoProject?.main_information?.number_of_keywords;

    return (
      <ButtonGroup className="mt-4">
        <Button
          variant="outline-primary"
          className="rounded-pill"
          onClick={() => {
            logtail.flush();
            prevStep();
          }}
        >
          <FontAwesomeIcon icon={'arrow-left'} /> Previous
        </Button>
        <SaveAsDraftWithNoteModal
          dataToBeDrafted={selectedFlatRows.map(
            row => row.original[0]?.keyword
          )}
        />
        <OverlayTrigger
          placement={'top'}
          overlay={
            isNextStepButtonDisabled ? (
              <Tooltip>
                {selectedFlatRows?.length < 1
                  ? `You have to select at least 1 keyword`
                  : `You can select upto
                    ${currentSeoProject?.main_information?.number_of_keywords} keywords`}
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              className="rounded-pill ms-2"
              disabled={isNextStepButtonDisabled}
              onClick={() => {
                logtail.flush();
                saveKeywords(selectedFlatRows);
              }}
            >
              {UpdateSeoProjectDraftMutation.isLoading ? (
                <>
                  Updating draft... <FontAwesomeIcon icon={'spinner'} spin />
                </>
              ) : (
                <>
                  Next Step: Match keywords to url{' '}
                  <FontAwesomeIcon icon={'arrow-right'} />
                </>
              )}
            </Button>
          </span>
        </OverlayTrigger>
      </ButtonGroup>
    );
  };

  const SelectedKeywordsTable = ({ selectedRowIds, toggleRowSelected }) => {
    const selectedFlatRows = Object.keys(selectedRowIds).map(
      rowId => keywords[rowId]
    );

    const selectedKeywordsColumns = [...columns].map(column => {
      if (column?.id == 'keyword') {
        let nc = { ...column };
        nc.Cell = ({ cell }) => {
          return (
            <>
              <FontAwesomeIcon
                className="cursor-pointer text-primary fs-0"
                icon={faTimesCircle}
                title="Un-check"
                onClick={() => {
                  toggleRowSelected(
                    Object.keys(selectedRowIds)[cell.row.index],
                    false
                  );
                }}
              />{' '}
              {cell.value}
            </>
          );
        };
        return nc;
      }

      return column;
    });

    return (
      <AdvanceTableWrapper
        columns={selectedKeywordsColumns}
        data={selectedFlatRows}
        pagination
        sortable
        sortByOptions={useMemo(
          () => [
            {
              id: 'score',
              desc: true
            }
          ],
          []
        )}
      >
        <Card>
          <Card.Body className="m-3">
            <Row className="align-items-center mb-3">
              <Col>
                {currentSeoProject?.main_information?.number_of_keywords == "custom" ?
                (
                    <h5
                    className={classNames({
                      'text-danger':
                      +currentSeoProject?.main_information?.number_of_keywords < 5,
                      'text-success':
                      selectedFlatRows?.length >= 5                    
                    })}
                    >
                    You have selected {selectedFlatRows?.length} keywords
                    </h5>
                ) : 
                (
                  <h5
                  className={classNames({
                    'text-danger':
                    selectedFlatRows?.length >
                    +currentSeoProject?.main_information?.number_of_keywords,
                    'text-success':
                    selectedFlatRows?.length ==
                    +currentSeoProject?.main_information?.number_of_keywords
                  })}
                  >
                  You have selected {selectedFlatRows?.length} out of{' '}
                  {+currentSeoProject?.main_information?.number_of_keywords}{' '}
                  keywords
                  </h5>
                )}
              </Col>
              <Col xs="auto">
                <ChangeNumberOfKeywordsModal />
              </Col>
            </Row>
            <AdvanceTable
              apiCallInProcess={KeywordsMutation?.isLoading}
              table
              headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              customNoResultFoundMessage="Please select any of keywords"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
    );
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={keywords}
      selection
      perPage={50}
      sortable
      maxSelection={+currentSeoProject?.main_information?.number_of_keywords}
      initialSelectedRowIds={initiallySelectedRowIds}
      pagination
      uncheckAllButton={false}
      showBulkSelectRowCheckbox={false}
      sortByOptions={useMemo(
        () => [
          {
            id: 'score',
            desc: true
          }
        ],
        []
      )}
    >
      <SelectedKeywordsTable table />

      <Card className="mt-5">
        <Card.Body className="m-3">
          <Row className="align-items-center mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col>{/* <TopSelectedRowsCounter table /> */}</Col>
            <Col xs="auto">
              <ManualAddKeywordModal
                table
                setKeywords={setKeywords}
                keywords={keywords}
                maxSelection={
                  +currentSeoProject?.main_information?.number_of_keywords
                }
                setInitiallySelectedRowIds={setInitiallySelectedRowIds}
                variant="outline-primary"
                className="rounded-pill"
              />
            </Col>
          </Row>
          <AdvanceTable
            apiCallInProcess={KeywordsMutation?.isLoading}
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <AdvanceTableFooter
            // @ts-ignore
            showSelectedRowCount
            table
            className="mt-3 mx-3"
            rowCount={keywords?.length}
            rowInfo
            navButtons
            rowsPerPageOptions={[25, 50, 75, 100, 150, 200]}
            rowsPerPageSelection
          />
          <Flex justifyContent="center">
            <NextPrevButtons table data={keywords} />
          </Flex>
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  );
};

const TopSelectedRowsCounter = ({ selectedRowIds }) => {
  return (
    <h6 className="mt-1 mb-0 text-center">
      {Object.keys(selectedRowIds).length > 0
        ? 'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
        : ''}
    </h6>
  );
};

export default SelectKeywords;
